import React from "react";
import { Container } from "react-bootstrap";
import { CustomHeader } from "../Hero/styled";
import Signature from "../Signature";
import "aos/dist/aos.css"; // Import AOS styles
import { NavLink } from "react-router-dom";
import { StyledLink } from "../Nav/styled";

const Footer = () => {
  return (
    <footer style={{ padding: "0 0 30px" }} className="mt-5">
      <Container className="d-md-block d-flex flex-column align-items-center">
        <CustomHeader>MUCAHIT KUK</CustomHeader>
        <CustomHeader>SOFTWARE DEVELOPER</CustomHeader>
        <div className="d-flex flex-column align-items-center gap-3 flex-md-row justify-content-between mt-4">
          <div className="d-flex" style={{ gap: "2rem" }}>
            <a
              href="https://www.linkedin.com/in/mucahitkuk/"
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1,
                letterSpacing: "-0.5px",
                textDecoration: "none",
                color: "black",
              }}
            >
              LinkedIn
            </a>
            <a
              href="https://open.spotify.com/user/11134272821"
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1,
                letterSpacing: "-0.5px",
                textDecoration: "none",
                color: "black",
              }}
            >
              Spotify
            </a>
            <a
              href="https://github.com/mchtkuk"
              target="_blank"
              rel="noreferrer"
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1,
                letterSpacing: "-0.5px",
                textDecoration: "none",
                color: "black",
              }}
            >
              Github
            </a>
            <NavLink
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1,
                letterSpacing: "-0.5px",
                textDecoration: "none",
                color: "black",
              }}
              to="/certificates"
              onClick={() => window.scrollTo(0, 0)}
            >
              Certificates
            </NavLink>
          </div>
          <div>
            <h2
              style={{
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1,
                letterSpacing: "-0.5px",
                textDecoration: "none",
                color: "black",
              }}
            ></h2>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Signature
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          />
        </div>
        <div>
          <h6>Disclaimer: This site is still work in progress.</h6>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
