import React from "react";
import transition from "../transition";
import Hero from "../components/Hero/Hero";
import Main from "../components/Main/Main";
import Footer from "../components/Footer/Footer";
import { ParallaxText } from "../components/Parallex/Parallex";

const Home = () => {
  return (
    <>
      <Hero />
      <Main />
      <ParallaxText baseVelocity={-1}>
        REACT - REACT NATIVE - JAVASCRIPT - HTML - CSS - TYPESCRIPT - SASS/LESS
        - MYSQL - NODE/EXPRESS - REDUX - CI/CD -
      </ParallaxText>
      <Footer />
    </>
  );
};

export default transition(Home);
