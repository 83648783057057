import React from "react";
import { Button, Container } from "react-bootstrap";
import {
  CustomHeader,
  CustomText2,
  CustomWrapper,
  TextWrapper2,
} from "./styled";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
import { StyledLink } from "../Nav/styled";

const Hero = () => {
  AOS.init();
  return (
    <Container className="mt-4 justify-content-center d-flex align-items-center align-items-sm-baseline flex-column align-content-center">
      <CustomWrapper className="mt-2 d-sm-block d-flex text-sm-start text-center justify-content-center w-auto align-items-center align-items-sm-baseline">
        <CustomHeader
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          MUCAHIT KUK
        </CustomHeader>
        <CustomHeader
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          SOFTWARE DEVELOPER
        </CustomHeader>
      </CustomWrapper>
      <TextWrapper2 className="mt-2 d-sm-block d-flex text-sm-start text-center justify-content-center w-auto">
        <CustomText2
          className="text-black"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          Self taught software developer with a passion for bringing ideas to
          life in the digital world. My goal is to create visually and
          functionally remarkable websites and apps that provide smooth and
          engaging user experiences.
        </CustomText2>
      </TextWrapper2>
      <div className="mt-5" style={{ padding: "0 0 180px" }}>
        <Button
          data-aos="fade-up"
          data-aos-duration="1700"
          data-aos-delay="1000"
          size="lg"
          href="mailto:mucahit.kuk@gmail.com"
          variant="dark"
          style={{ borderRadius: 70, width: 250 }}
          className="p-2.5 bg-black text-white"
        >
          <span style={{ letterSpacing: "-0.5px" }}>Contact</span>
        </Button>
      </div>
    </Container>
  );
};

export default Hero;
